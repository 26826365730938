import React, { Component } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import ATF from 'src/components/ATF'
import Link from 'src/components/Link'
import { animations } from 'src/styles'

const ContactInfo = styled.div`
	p {
    margin-top: 1.5em;
    margin-bottom: 0;
    a {
    	position: relative;
    	border: none;
    	&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: -.01em;
				right: -.01em;
				height: 2px;
				background: currentcolor;
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform ${ animations.mediumSpeed } ease-in-out;
			}
			&:hover {
				&:after {
					transform-origin: left center;
					transform: scaleX(1);
				}
			}
    }
		b {
			display: block;
			font-weight: 700;
		}
	}
`

class NotFound extends Component {
	componentDidMount () {
		setTimeout(() => {
			navigate('/')
		}, 500)
	}

	render () {
		return (
			<ATF
				headline="Oh no!"
				headlineSize="h1"
				theme="blue"
				hAlignment="left"
				vAlignment="bottom"
				textAlignment="left"
				fullHeight={true}
				headerArea2={<div><p><a href="https://goo.gl/maps/pCGL7Tw6UPhqXLHP7" target="_blank">43-01 21st Street #326A <br/>Long Island City, NY 11101</a></p></div>}
				text={<div>
					<ContactInfo>
						<p>
							<b>Project inquiries</b><br/>
							<a target="_blank" href="mailto:hi@gordilsandwillis.com">hi@gordilsandwillis.com</a>
						</p>
						<p>
							<b>Join the team</b><br/>
							<a target="_blank" href="mailto:careers@gordilsandwillis.com">careers@gordilsandwillis.com</a>
						</p>
					</ContactInfo>
				</div>}
			>
				<ContactInfo>
				<p><Link to="https://instagram.com/gordilsandwillis" external settheme="bgColor" style="arrowLink">Follow Us</Link></p>
				</ContactInfo>
			</ATF>
		)
	}
}

export default NotFound
